<template>
  <div class="sb-feedback-item mt-5">
    <div class="sb-feedback-item__header">
      <div class="sb-feedback-item__header--left">
        <div class="header-bar">{{ feedback.phases.join(', ') }}</div>
        <div class="header-bar">
          {{ $t('Start Date') }}
          <div v-html="dateFormatInTimeZone(feedback.session_start)" class="date-time-zone"></div>
        </div>
        <div class="header-bar">
          {{ $t('End Date') }}
          <div v-html="dateFormatInTimeZone(feedback.session_end)" class="date-time-zone"></div>
        </div>
      </div>
    </div>
    <div class="sb-feedback-item__body">
      <div class="sb-feedback-item__body--title">
        {{ $t('Session ID') }}: {{ feedback.id }}
      </div>
      <div class="feedback-info">
        <div class="feedback-info__title">
          {{ $t('Product Areas') }}
        </div>
        <div class="feedback-info__value">
          <div class="feedback-info__value--item" v-for="area in feedback.product_areas.slice(0, 3)">{{ area }}</div>
          <el-dropdown v-if="feedback.product_areas.length > 3">
            <span class="feedback-info__value--item cursor-pointer">
              +{{ feedback.product_areas.length - 3 }}
            </span>
            <template #dropdown>
              <template v-for="(area, index) in feedback.product_areas">
                <div v-if="index > 2" class="footer-product-area__dropdown">{{ area }}</div>
              </template>
            </template>
          </el-dropdown>
        </div>
      </div>

      <div class="feedback-info">
        <div class="feedback-info__title">
          {{ $t('Countries') }}
        </div>
        <div class="feedback-info__value">
          <div class="feedback-info__value--item" v-for="country in feedback.countries.slice(0, 3)">{{ country }}</div>
          <el-dropdown v-if="feedback.countries.length > 3">
            <span class="feedback-info__value--item cursor-pointer">
              +{{ feedback.countries.length - 3 }}
            </span>
            <template #dropdown>
              <template v-for="(country, index) in feedback.countries">
                <div v-if="index > 2" class="footer-product-area__dropdown">{{ country }}</div>
              </template>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormatInTimeZone } from "@/core/helpers/GlobalHelper";

export default {
  name: "BankFeedbackSingleItem",
  props: {
    feedback: Object,
    isDetailsPage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dateFormatInTimeZone
    }
  },
};
</script>

<style lang="scss">
.sb-feedback-item {
  background: #FFFFFF;
  box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: calc(100% - 350px);
  overflow: hidden;
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 17px 20px 17px 10px;
    background: #FAFAFA;
    height: 52px;
    &--left {
      display: flex;
      align-items: center;
      width: 100%;
      overflow-x: auto;
      margin-bottom: -5px;
      > * {
        white-space: nowrap;
      }
      .header-bar {
        padding: 0 10px;
        font-weight: 400;
        font-size: 11px;
        line-height: 158.02%;
        color: #969696;
        &:not(:last-child) {
          border-right: 1px solid #969696;
        }
        &:nth-child(1) {
          color: #030303;
        }
      }
    }
    &--right {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-left: 10px;
      > * {
        white-space: nowrap;
      }
      .invitees-section {
        font-weight: 400;
        font-size: 11px;
        line-height: 158.02%;
        color: #969696;
        span {
          font-size: 16px;
          color: #000000;
          font-weight: 700;
        }
      }
    }
  }
  &__body {
    height: calc(100% - 52px);
    padding: 20px 29px 20px 23px;
    &--title {
      font-weight: 700;
      font-size: 18px;
      line-height: 158.02%;
      color: #2B2B2B;
      width: 100%;
      padding-bottom: 17px;
      border-bottom: 1px solid #E0E0E0;
    }
    .feedback-info {
      padding: 17px 0;
      &:last-child {
        border-top: 1px solid #E0E0E0;
        padding-bottom: 0;
      }
      &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 158.02%;
        color: #969696;
        margin-bottom: 8px;
      }
      &__value {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        &--item {
          background: #F6F6F6;
          border-radius: 5px;
          padding: 8px 7px;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
        }
        .el-dropdown > div {
          display: flex;
        }
      }
    }
  }
  @media (max-width: 1285px) {
    width: 100%;
  }
}

.footer-product-area__dropdown {
  padding: 5px 10px;
}
</style>
