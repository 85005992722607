<template>
    <div class="mx-0 single-feedback-item platform-dashboard-pages">
        <a href="" @click.prevent="goBack" class="back-to-btn">
            <img src="/media/buying/icons/arrow-left.svg" alt="">
            {{ $t('Back to Sessions') }}
        </a>
        <div class="mb-4">
            <BankFeedbackItemFilter
              v-if="!filterLoading"
              @apply-filters="applyFilters"
            />
        </div>
        <CustomLoader height="400px" v-if="loading"/>
        <template v-else-if="survey">
            <div class="mb-9 info-section">
                <BankFeedbackSingleItem
                    :feedback="survey.feedbacksSessionData"
                />
                <div class="card mt-5 feedback-session-score">
                    <CircleChart
                        :labelTop="$t('Feedback Session Score')"
                        :chartData="survey.feedbackSessionScore"
                        :chartConfigs="{
                          width: 300,
                          height: 0.8 * 260,
                          valueVisible: false
                        }"
                        :values="[survey.feedbackSessionScore.score, survey.feedbackSessionScore.importance]"
                    />
                    <div class="score-label">
                        <div class="score-label__item bankScore_score"
                             :class="getStatusClass(survey.feedbackSessionScore.score)">
                            <div class="score-label__item--value">
                                {{ survey.feedbackSessionScore.score }}
                            </div>
                            <div class="score-label__item--label">
                                {{ $t('Score') }}
                            </div>
                        </div>
                        <div class="score-label__item bankScore_importance"
                             :class="getStatusClass(survey.feedbackSessionScore.importance)">
                            <div class="score-label__item--value">
                                {{ survey.feedbackSessionScore.importance }}
                            </div>
                            <div class="score-label__item--label">
                                {{ $t('Importance') }}
                            </div>
                        </div>
                        <div class="score-label__item bankScore_gap"
                             :class="getGapStatusClass(survey.feedbackSessionScore.gap)">
                            <div class="score-label__item--value">
                                {{ survey.feedbackSessionScore.gap }}
                            </div>
                            <div class="score-label__item--label">
                                {{ $t('Gap') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-9 invited-section">
                <BankFeedbackItemInvitedInfo
                    :data="invitedData"
                />
                <FeedbackItemInvitedCustomers
                    :businesses="survey.businesses"
                    :replyRate="survey.replyRate"
                />
            </div>

            <QuestionsTabsContainer
                v-if="survey"
                :is-loading="loading"
                :benchmark-questions-list="survey.allQuestions"
                :custom-questions="survey.customQuestionsSectionData"
                @onClickQuestionItem="handleClickQuestionItem"
                @onSortingChange="survey.sortQuestionsBy($event)"
            />
        </template>
    </div>
</template>
<script>

import BankFeedbackSingleItem from "@/buying-teams/pages/bank/feedbacks/sections/BankFeedbackSingleItem";
import store from "@/store";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import BankFeedbackItemFilter from "@/buying-teams/pages/bank/feedbacks/sections/BankFeedbackItemFilter";
import FeedbackItemInvitedCustomers
    from "@/buying-teams/pages/bank/feedbacks/sections/BankFeedbackItemInvitedCustomers";
import BankFeedbackItemInvitedInfo from "@/buying-teams/pages/bank/feedbacks/sections/BankFeedbackItemInvitedInfo";
import {getGapStatusClass, getStatusClass} from "@/core/helpers/GlobalHelper";
import CircleChart from "@/buying-teams/shared-components/charts/CircleChart";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import moment from "moment";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import QuestionsTabsContainer from "@/buying-teams/shared-components/questions/QuestionsTabsContainer";

export default {
    name: "BankSingleFeedback",
    components: {
        QuestionsTabsContainer,
        CustomLoader,
        CircleChart,
        BankFeedbackItemInvitedInfo,
        FeedbackItemInvitedCustomers,
        BankFeedbackSingleItem,
        BankFeedbackItemFilter,
    },
    computed: {
        bankSingleSurveyFilterData() {
            return store.getters.getBankSingleSurveyFilterData;
        },
        invitedData() {
            return [
                {
                    title: "All Invited",
                    buttonText: "View All Invited",
                    customers: this.survey.invitedCustomers,
                    respondents: this.survey.invitedRespondents
                },
                {
                    title: "All Replied",
                    buttonText: "View All Replied",
                    customers: this.survey.repliedCustomers,
                    respondents: this.survey.repliedRespondents
                }
            ]
        }
    },

    data() {
        return {
            filterLoading: true,
            getStatusClass,
            getGapStatusClass,
            survey: null,
            loading: true,
        };
    },

    async mounted() {
        setCurrentPageBreadcrumbs({
            title: this.$t("Feedback Session"),
            subTitle: this.$t("Score or review your Banks' performance")
        });

        await store.dispatch('initFiltersData', {
            type: BankFiltersTypeEnum.SINGLE_SURVEY,
            payload: {survey_id: +this.$route.params.id}
        }).then(() => {
            this.filterLoading = false;
        })

        this.getFeedbackData(this.bankSingleSurveyFilterData);
    },

    methods: {
        async getFeedbackData(filter) {
            this.loading = true;
            this.survey  = await store.dispatch('getBankFeedbacksSessionDetails', {
                survey_id: +this.$route.params.id,
                filter
            }).finally(() => {
                this.loading = false;
            })
            setCurrentPageBreadcrumbs({
                title: `${this.$t("Feedback Session")} <span class="feedbacks_id">#${this.survey.feedbacksSessionData.id}</span>`,
                subTitle: this.survey.feedbacksSessionData.status.statusName ? `<div class="feedbacks_time">
          <span class="${this.survey.feedbacksSessionData.status.statusClass}">${this.$t(this.survey.feedbacksSessionData.status.statusName)} </span>
          - ${moment(this.survey.feedbacksSessionData.session_start).format('DD.MM.YYYY')}
          - ${moment(this.survey.feedbacksSessionData.session_end).format('DD.MM.YYYY')}
        </div>` : this.$t("Score or review your Banks' performance")
            });
        },
        applyFilters(filters) {
            this.getFeedbackData(filters)
        },
        goBack() {
            this.$router.go(-1);
        },
        async handleClickQuestionItem(question, newTab) {
            this.survey.customQuestionsSectionData.redirectLoading = question.id;

            const filters = await store.dispatch('singleQuestionRedirectFilterState', {
                questionId: question.id,
                filterData: {
                    ...this.bankSingleSurveyFilterData,
                    survey_ids: [+this.$route.params.id]
                },
            });

            store.commit('SAVE_BANK_SINGLE_QUESTION_FILTER', filters);
            this.survey.customQuestionsSectionData.redirectLoading = null;

            const questionUrl = `/bank/questions/${question.id}${question.isCustom ? '?isCustom=true' : ''}`;

            if (newTab) {
                window.open(questionUrl, '_blank');
            } else {
                this.$router.push(questionUrl);
            }
        }
    }
};
</script>
<style lang="scss">

#kt_header_menu_nav {
    h1 {
        display: flex;
        align-items: center;

        span.feedbacks_id {
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.21);
            border-radius: 3.6px;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #000000;
            padding: 5px;
            margin-left: 16px;
        }
    }

    .subTitle .feedbacks_time {
        span {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;

            &.live {
                color: #000000;
            }

            &.secondary {
                color: #9CA0AB;
            }

            &.primary {
                color: #435BF4;
            }

            &.danger {
                color: #E22D21;
            }

            &.success {
                color: #16D278;
            }
        }
    }
}

.single-feedback-item {
    .feedback-item {
        flex: 1;
    }

    .info-section, .invited-section {
        display: flex;
        gap: 16px;

        @media (max-width: 1285px) {
            flex-direction: column;
        }
    }

    .feedback-session-score {
        width: 335px;
        padding-bottom: 0;

        .subtitle {
            text-align: center;
        }

        .score-label {
            display: flex;
            justify-content: space-between;

            &__item {
                padding-bottom: 14px;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    height: 4px;
                    width: 100%;
                    border-radius: 4px;
                }

                &--value {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 13px;
                    color: rgb(0, 0, 0, 0.87);
                    margin-bottom: 8px;
                }

                &--label {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: rgb(0, 0, 0, 0.5);
                    padding-left: 16px;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 8.3px;
                        height: 8.3px;
                        border-radius: 50%;
                    }
                }

                &.status-danger:after {
                    background: #E22D21;
                }

                &.status-warning:after {
                    background: #FAD03B;
                }

                &.status-success:after {
                    background: #2BC490;
                }

                &.bankScore_score .score-label__item--label:after {
                    background: #435BF4;
                }

                &.bankScore_importance {
                    &:after {
                        background: transparent;
                    }

                    .score-label__item--label:after {
                        background: #CED4F8;
                    }
                }

                &.bankScore_gap {
                    &.status-danger .score-label__item--label:after {
                        background: #ED7B74;
                    }

                    &.status-warning .score-label__item--label:after {
                        background: #E6B25F;
                    }

                    &.status-success .score-label__item--label:after {
                        background: #63B775;
                    }
                }
            }
        }

        @media (max-width: 1285px) {
            width: 100%;
        }
    }
}

@media (max-width: 1460px) {
    .single-feedback-item .invited-section {
        flex-direction: column;
    }
}
</style>
