<template>
    <div class="dashboard-common-filters" id="bankFeedbackItemFilter">
        <div class="dashboard-common-filters__top">
            <div class="dashboard-common-filters__top--title">
                <span>{{ $t("Filters") }}</span>
                <a v-if="showFilterBadges" @click.prevent="setFiltersDefaultState" href="">{{ $t("Clear All") }}</a>
            </div>

            <FiltersScoreImportanceInfo />
        </div>

        <div class="section-card">
            <div class="dashboard-common-filters__card">
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Product Areas") }}</div>
                    <SelectBoxWithModal
                        v-model="filterData.product_areas"
                        self-value
                        multiple
                        show-badge
                        :options="filterAllData.all_product_areas"
                        :all-selected-text="$t(filterAllSelectedText(BankFiltersSelectTypeEnum.PRODUCT_AREAS))"
                        :custom-selection-text="$t(filterCustomSelectionText(BankFiltersSelectTypeEnum.PRODUCT_AREAS))"
                        @onTouch="isFilterTouched = true"
                    />
                </div>
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Industries") }}</div>

                    <SelectBoxWithModal
                      v-model="filterData.industries"
                      multiple
                      show-badge
                      self-value
                      :options="filterAllData.all_industries"
                      :all-selected-text="$t(filterAllSelectedText(BankFiltersSelectTypeEnum.INDUSTRIES))"
                      :custom-selection-text="$t(filterCustomSelectionText(BankFiltersSelectTypeEnum.INDUSTRIES))"
                      :placeholder="$t('Select Industries')"
                      @onTouch="isFilterTouched = true"
                    />
                </div>
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Customer’s Global Headquarter") }}</div>

                    <SelectBoxWithModal
                      v-model="filterData.customers_hq"
                      multiple
                      show-badge
                      self-value
                      :options="filterAllData.all_customers_hq"
                      :all-selected-text="$t(filterAllSelectedText(BankFiltersSelectTypeEnum.CUSTOMERS_GLOBAL_HQ))"
                      :custom-selection-text="$t(filterCustomSelectionText(BankFiltersSelectTypeEnum.CUSTOMERS_GLOBAL_HQ))"
                      :placeholder="$t('Select Countries')"
                      @onTouch="isFilterTouched = true"
                    />
                </div>
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Customer Tier") }}</div>

                    <SelectBoxWithModal
                      v-model="filterData.tiers"
                      multiple
                      show-badge
                      :options="filterAllData.all_tiers"
                      :all-selected-text="$t(filterAllSelectedText(BankFiltersSelectTypeEnum.CUSTOMER_TIER))"
                      :custom-selection-text="$t(filterCustomSelectionText(BankFiltersSelectTypeEnum.CUSTOMER_TIER))"
                      :placeholder="$t('Select Tiers')"
                      @onTouch="isFilterTouched = true"
                    />
                </div>
                <div class="dashboard-common-filters__item apply-section">
                    <button @click="handleApplyFilters" class="main-btn btn apply-section__btn">
                        {{ $t("Apply") }}

                        <span
                          v-show="isFilterTouched && !isFilterApplied"
                          class="apply-section__btn--badge"
                        >
                            <BusinessFilterAppliedBadges
                              :filterData="filterData"
                              :allData="filterAllData"
                              :forceShow="showFilterBadges === 0"
                              @getSize="handleGetSize"
                            />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FiltersScoreImportanceInfo from "@/buying-teams/shared-components/utils/FiltersScoreImportanceInfo.vue";
import SelectBoxWithModal from "@/buying-teams/shared-components/inputs/SelectBoxWithModal.vue";
import store from "@/store";
import BusinessFilterAppliedBadges from "@/buying-teams/shared-components/filter/BusinessFilterAppliedBadges.vue";
import { filterAllSelectedText, filterCustomSelectionText } from "@/core/helpers/FiltersHelper";
import { BankFiltersSelectTypeEnum } from "@/store/enums/bank/BankFiltersSelectTypeEnum";

export default {
    name: "BankFeedbackItemFilter",
    components: {
        BusinessFilterAppliedBadges,
        SelectBoxWithModal,
        FiltersScoreImportanceInfo
    },
    emits: ["apply-filters"],
    data() {
        return {
            isFilterTouched: false,
            showFilterBadges: false,
            isFilterApplied: true,
            filterData: {
                product_areas: [],
                industries: [],
                customers_hq: [],
                tiers: [],
            },
            filterAllSelectedText,
            filterCustomSelectionText,
            BankFiltersSelectTypeEnum,
        }
    },
    computed: {
        bankSingleSurveyFilterData() {
            return store.getters.getBankSingleSurveyFilterData;
        },
        singleSurveyFilter() {
            return store.getters.getBankFiltersState.singleSurvey;
        },
        tiers() {
            return this.singleSurveyFilter.tiers.map((item) => {
                return {
                    name: `${this.$t('Tier')} ${item}`,
                    id: item
                }
            });
        },
        filterAllData() {
            return {
                all_product_areas: this.singleSurveyFilter.product_areas,
                all_industries: this.singleSurveyFilter.industries,
                all_customers_hq: this.singleSurveyFilter.customers_hq,
                all_tiers: this.tiers,
            };
        },
    },
    created() {
        this.setFilterData(this.bankSingleSurveyFilterData);
    },
    methods: {
        handleApplyFilters() {
            this.isFilterApplied = true;
            this.$emit('apply-filters', this.filterData);
        },
        setFiltersDefaultState() {
            this.isFilterApplied = false;
            this.isFilterTouched = false;
            this.filterData = {
                product_areas: this.filterAllData.all_product_areas,
                industries: this.filterAllData.all_industries,
                customers_hq: this.filterAllData.all_customers_hq,
                tiers: this.filterAllData.all_tiers.map(t => t.id),
            };
            this.$emit('apply-filters', this.filterData);
        },
        handleGetSize(touchedCount) {
            this.isFilterApplied = false;
            this.showFilterBadges = touchedCount;
        },
        setFilterData(newFilters) {
            for (let [key, value] of Object.entries(newFilters)) {
                this.filterData[key] = value;
            }
        }
    }
}
</script>

<style lang="scss">
#bankFeedbackItemFilter {
    .section-card {
        padding: 14px 14px 8px 10px;
    }
    .dashboard-common-filters__card {
        padding: 0;
    }
    .dashboard-common-filters__item {
        .el-select, .el-input, .el-input__inner {
            width: 100%;
        }
    }
    .apply-section__btn {
        margin-top: 0;
    }
}
</style>
