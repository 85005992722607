<template>
  <div class="feedback-item-invited-customers card">
    <div class="feedback-item-invited-customers-header">
      <span class="title">
        {{$t('Current')}}
      </span>
      <span class="extra" @click="visibleInvitedCustomersPopup = true">
        {{ $t('View Details') }}
      </span>
    </div>
    <div class="body">
      <div class="body__title">{{ $t('Reply rate') }}</div>
      <div class="body__value">
        {{ replyRate }}
        <span>%</span>
      </div>
    </div>
  </div>

  <AllInvitedCustomersPopup
    v-if="visibleInvitedCustomersPopup"
    :visibility="visibleInvitedCustomersPopup"
    :customers-list="businesses"
    @close="visibleInvitedCustomersPopup = false"
  />
</template>

<script>
import AllInvitedCustomersPopup from "@/buying-teams/pages/bank/feedbacks/components/AllInvitedCustomersPopup";

export default {
  name: "BankFeedbackItemInvitedCustomers",
  components: {
    AllInvitedCustomersPopup
  },
  props: {
    replyRate: [String, Number],
    businesses: Array,
  },
  data() {
    return {
      visibleInvitedCustomersPopup: false
    }
  }
};
</script>
<style lang="scss" scoped>

.feedback-item-invited-customers {
  padding: 21px;
  width: 335px;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: 700;
      font-size: 18px;
      color: #000000;
      opacity: 0.87;
      margin-right: 30px;
    }

    .extra{
      font-weight: 600;
      font-size: 12px;
      color: #435BF4;
      cursor: pointer;
    }

  }
  .body{
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      font-weight: 500;
      font-size: 12px;
      line-height: 13px;
      color: #717171;
    }
    &__value {
      font-weight: 700;
      font-size: 36px;
      color: #000000;
      opacity: 0.87;
      line-height: 0.8;
      display: flex;
      align-items: flex-end;
      span {
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 1460px) {
  .feedback-item-invited-customers {
    width: 100%;
  }
}
</style>
