<template>
  <el-dialog
    v-model="visibility"
    center
    width="576px"
    :show-close="true"
    @close="$emit('close')"
    custom-class="invited-customers-popup"
  >
    <div class="invited-customers__header">
      <span>{{ $t('All Invited Customers') }}</span>
      <div class="customers-count">
        <b>{{ filteredItems.length }}</b> results
      </div>
      <p>{{ $t('The results are based on the filters applied.') }}</p>
      <div class="customers-search">
        <img src="/media/buying/icons/search-black.svg" alt="">
        <input type="text" :placeholder="$t('Search Customer or Employee')" v-model="search">
      </div>
    </div>

    <el-collapse v-model="activeTab" accordion class="invited-customers-popup__accordion">
      <el-collapse-item
        v-for="item in filteredItems"
        :key="item.id"
        :name="item.id"
        :class="{'d-none': activeTab && +activeTab !== item.id}"
      >
        <template #title>
          <div class="accordion__header">
            <img class="accordion__header--img" :src="item.icon_path">
            <div class="accordion__header--content">
              <div class="accordion__header--title">
                {{ item.name }}
              </div>

              <div class="customers-list__button">
                <div class="customers-invited" v-if="!item.replied">{{ $t('Invited') }}</div>
                <div class="customers-replied" v-else>{{ $t('Replied') }}</div>

                <div class="customers-users">
                  <img src="/media/buying/icons/users-icon.svg" alt="">
                  <span v-if="!item.replied">
                    {{ item.allUsers }}
                  </span>
                  <span v-else>
                    {{ item.usersReplied }}/{{ item.allUsers }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="users-list">
          <div class="users-list__item" v-for="user in item.usersList" :key="'user' + user.id">
            <div class="users-list-left">
              <div class="users-list__item--check" v-if="item.replied">
                <img src="/media/buying/icons/replied-icon.svg" alt="" v-if="user.is_replied">
              </div>
              <div class="users-list__item--name">
                  {{ user.displayName }}
              </div>
            </div>
            <div class="users-list-right">
              <div class="users-list__item--value">
                <img src="/media/buying/icons/user-default.svg"  alt="">
                <span>{{ user.job_title }}</span>
              </div>
              <div class="users-list__item--value">
                <img src="/media/buying/icons/map-location.svg" width="10" height="13" alt="">
                <span>{{ user.country ? user.country : '-' }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>

    <p v-if="!filteredItems.length" class="text-center mt-10">{{ $t('No Data') }}</p>
  </el-dialog>
</template>

<script>
export default {
  name: "AllInvitedCustomersPopup",
  data() {
    return {
      search: '',
      activeTab: null
    }
  },
  props: {
    visibility: {
      type: Boolean,
      default: false
    },
    customersList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    filteredItems() {
      if (!this.search) return this.customersList;
      else {
        return this.customersList.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()))
      }
    }
  }
}
</script>

<style lang="scss">
.invited-customers-popup {
  &__accordion {
    border-top: none;
    border-bottom: none;
    .users-list {
      background: #F7F7F7;
      border-radius: 12px 12px 0px 12px;
      padding: 0 15px 17px 15px;
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 39px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .users-list {
          &-left,
          &-right {
            display: flex;
            align-items: center;
          }
        }
        &--check {
          width: 14px;
          margin-right: 8px;
        }
        &--name {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &--value {
          display: flex;
          align-items: center;
          &:first-child {
            width: 150px;
          }
          &:last-child {
            width: 100px;
          }
          img {
            width: 14px;
            margin-right: 4px;
          }
          span {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #8B8B8B;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .el-collapse-item {
      &:not(:last-child):not(.is-active) {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          left: 68px;
          height: 1px;
          background: rgba(0, 0, 0, 0.1);
        }
      }
      &__arrow {
        transform: rotate(90deg);
        margin-right: 4px;
        margin-left: 11px;
      }
      &.is-active {
        .el-collapse-item__arrow {
          transform: rotate(-90deg);
        }
      }
      &__wrap {
        border-bottom: none;
      }
      &__header {
        height: 60px;
        border-bottom: none;
        .accordion {
          &__header {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            &--img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              object-fit: cover;
              margin-left: 15px;
              margin-right: 23px;
            }
            &--content {
              width: calc(100% - 68px);
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .customers-list__button {
                user-select: none;
                display: flex;
                width: 194px;
                .customers-invited {
                  background: #F8F8F8;
                  border-radius: 2.37209px;
                  border: none;
                  font-weight: 700;
                  font-size: 14.2326px;
                  line-height: 17px;
                  text-align: center;
                  color: #2B2B2B;
                  padding: 7px;
                  min-width: 100px;
                }
                .customers-replied {
                  background: rgba(95, 251, 40, 0.14);
                  border-radius: 2.37209px;
                  font-weight: 700;
                  font-size: 14.2326px;
                  line-height: 17px;
                  text-align: center;
                  color: rgba(10, 136, 45, 0.6);
                  padding: 7px;
                  min-width: 100px;
                }
                .customers-users {
                  display: flex;
                  background: #F8F8F8;
                  border-radius: 2.37209px;
                  margin-left: 4px;
                  min-width: 90px;
                  align-items: center;
                  justify-content: center;
                  span {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 144.02%;
                    color: #2B2B2B;
                  }
                  img {
                    width: 15px;
                    margin-right: 8px;
                  }
                }
              }
            }
            &--title {
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              color: #000000;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: calc(100% - 194px);
            }
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    &__accordion {
      .el-collapse-item {
        &:not(:last-child):not(.is-active):after {
          left: 53px;
        }
        &__header {
          padding: 15px 0;
          height: auto;
          .accordion__header {
            &--title {
              font-size: 14px;
              max-width: 100%;
            }

            .accordion__header {
              &--img {
                margin-left: 0;
              }

              &--content {
                width: calc(100% - 53px);
                flex-direction: column;
                align-items: flex-start;
                .customers-list__button {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
