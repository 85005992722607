<template>
  <div class="feedback-item-invited-info card">
    <div v-for="(item, index) in data" :key="index" class="feedback-item-invited-info-items">
      <div class="title" :class="{isGreen: item.title === 'Replied'}">
        {{ $t(item.title) }}
      </div>
      <div class="d-flex justify-content-between flex-column flex-sm-row mt-3">
        <div class="item">
          <div class="item-col">
            <img src="/media/buying/icons/office-building.svg">
            <span class="text">{{ $t("Customers") }}</span>
          </div>
          <span class="count-large">{{ item.customers }}</span>
        </div>
        <div class="item">
          <div class="item-col">
            <img src="/media/buying/icons/share-user.svg">
            <span class="text">{{ $t("Respondents") }}</span>
          </div>
          <span class="count">{{ item.respondents }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BankFeedbackItemInvitedInfo",
  props: {
    data: Array,
  },
};
</script>
<style lang="scss">

.feedback-item-invited-info {
  &.card {
    padding: 0;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    opacity: 0.87;
    display: flex;
    justify-content: space-between;

    .extra{
      font-weight: 600;
      font-size: 12px;
      color: #435BF4;
      cursor: pointer;
    }
  }

  .feedback-item-invited-info-items {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 19px 27px;

    &:not(:last-child) {
      border-right: 1px solid #E0E0E0;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 30px;
      margin-left: 3px;

      .text {
        font-weight: 500;
        font-size: 12px;
        color: #717171;
        margin: 12px;
      }

      .count {

        font-weight: 500;
        font-size: 16px;
        color: #717171;
        opacity: 0.87;

        &-large {
          font-weight: 700;
          font-size: 20px;
          color: #212121;
          opacity: 0.87;
        }
      }

      &-col {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .total-percent {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 15px;

    .total-title {
      font-weight: 700;
      font-size: 18px;
      color: #000000;
      opacity: 0.87;
    }

    .percent {
      font-weight: 700;
      font-size: 36px;
      color: #000000;
      opacity: 0.87;
    }
  }

  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  @media(max-width: 655px){
    flex-direction: column;

    .feedback-item-invited-info-items{
      border-right: none;
      border-bottom: 1px solid #E0E0E0;
      gap: 10px;
      padding: 12px;
    }
    .total-percent {
      padding: 12px;
    }
  }
}
.invited-customers-popup {
  .el-dialog {
    &__headerbtn {
      top: 40px;
      right: 40px;
      width: 16px;
      height: 16px;
      display: flex;
    }
    &__header {
      padding: 0;
    }
    &__body {
      padding: 34px;
      .invited-customers {
        &__header {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          span {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            color: #000000;
          }
          .customers-count {
            font-weight: 700;
            font-size: 16px;
            line-height: 158.02%;
            color: #435BF4;
            padding: 6px 24px;
            margin-left: 16px;
            background: rgba(67, 91, 244, 0.04);
            border-radius: 50px;
          }
          p {
            display: inline-block;
            width: 100%;
            margin-top: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 178.52%;
            color: #434343;
            opacity: 0.6;
          }
          .customers-search {
            width: 100%;
            background: #FAFAFA;
            border-radius: 29px;
            position: relative;
            img {
              position: absolute;
              top: 50%;
              left: 38px;
              transform: translateY(-50%);
            }
            input {
              width: 100%;
              border: none;
              outline: none;
              padding-left: 66px;
              padding-top: 18px;
              padding-bottom: 18px;
              background: transparent;
            }
          }
        }
        &__body {
          .customers-list {
            margin-top: 30px;
            &--item {
              display: flex;
              justify-content: space-between;
              margin-bottom: 29px;
            }
            &__name {
              display: flex;
              align-items: center;
              img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 23px;
              }
              span {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
              }
              &.item-replied {
                opacity: 0.5;
              }
            }
            &__button {
              user-select: none;
              .customers-invited {
                background: #F8F8F8;
                border-radius: 2.37209px;
                border: none;
                font-weight: 700;
                font-size: 14.2326px;
                line-height: 17px;
                text-align: center;
                color: #2B2B2B;
                padding: 7px;
                min-width: 100px;
                cursor: default;
              }
              .customers-replied {
                background: rgba(95, 251, 40, 0.14);
                border-radius: 2.37209px;
                font-weight: 700;
                font-size: 14.2326px;
                line-height: 17px;
                text-align: center;
                color: rgba(10, 136, 45, 0.6);
                padding: 7px;
                min-width: 100px;
                cursor: default;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .invited-customers-popup {
    --el-dialog-width: 98% !important;
    .el-dialog__body {
      padding: 15px;
    }
    .el-dialog__headerbtn {
      top: 15px;
      right: 15px;
    }
  }
}
@media (max-width: 1080px) {
  .feedback-item-invited-info.card {
    display: flex;
    flex-direction: column;
  }
  .feedback-item-invited-info .feedback-item-invited-info-items {
    flex: revert;
    width: 100%;
    border-right: 0 !important;
    border-bottom: 1px solid #E0E0E0;
  }
}
</style>
